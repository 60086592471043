/* --------------- Tabs --------------- */

.tabnav {
  margin: 20px 0 0;
  font-weight: 700;
  ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      width: 33.3333%;
      border: 1px solid #343a40;
      border-right: none;
      border-bottom: none;
      &:last-child {
        border-right: 1px solid #343a40;
      }
    }
  }
  a {
    text-decoration: none;
    display: block;
    padding: 10px;
    &.active {
      background: #2d292d;
      .light-mode & {
        background: #e0e0e0;
      }
    }
  }
}

.tabs {
  overflow: auto;
  .tab {
    display: none;
  }
  .active {
    display: block;
  }
}

/* --------------- Listings --------------- */

.listings {
  border-collapse: collapse;
  width: 100%;
  text-align: left;
  margin: 0 0 50px;
  th,
  td {
    border: 1px solid #6c757d;
    padding: 10px;
    width: 20%;
    cursor: pointer;
  }
  th {
    background: #202327;
    color: #6c757d;
    .light-mode & {
      background: lightgrey;
    }
  }
  a {
    text-decoration: none;
  }
  &.slim {
    th:last-child, 
    td:last-child {
      width: 80%;
    }
  }
  .edit-button {
    font-size: 12px;
  }
}