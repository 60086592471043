
/* --------------- Totals --------------- */

.totals {
	margin: 20px 0;
	text-align: center;
	@media (min-width: 600px) {
		font-weight: 700;
		margin: 40px 0;
		display: flex;
		flex-wrap: wrap;
	}
	@media (min-width: 900px) {
		justify-content: space-between;
		text-align: left;
	}
	.total {
		margin-bottom: 10px;
		@media (min-width: 600px) {
			width: 50%;
		}
		@media (min-width: 900px) {
			width: auto;
			margin-bottom: 0;
		}
	}
}
