
@import 'reset';

/* --------------- Basic Layout --------------- */

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  color: #f5f2f5;
  background: #1F1A1F;
}

.light-mode {
  background: #f5f2f5;
  color: #1F1A1F;
}

.modal-open {
  overflow: hidden;
}

.action {
  margin: 20px 0;
}

.split {
  display: flex;
  justify-content: space-between;
}

.wrap {
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1500px;
}

/* --------------- User Nav --------------- */

.site-nav {
  margin: 10px 0;
  text-align: center;
  @media (min-width: 600px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-content: space-between;
  }
  .btn-add {
    display: none;
    order: 0;
    @media (min-width: 600px) {
      display: inline-block;
    }
  }
}

.add-mb {
  text-align: center;
  margin: 20px 0 30px;
  @media (min-width: 600px) {
    display: none;
  }
}

/* --------------- Profile Nav --------------- */

.profile-name {
  margin-right: 10px;
  font-size: .8rem;
}

.profile-img {
  width: 30px;
  height: auto;
  border-radius: 50%;
}

.profile-nav {
  display: flex;
  align-items: center;
  justify-content: center;
  order: 0;
  margin-bottom: 10px;
  @media (min-width: 500px) {
    order: 1;
  }
  .btn:last-child {
    margin-left: 8px;
  }
}

/* --------------- Login --------------- */

.login {
  text-align: center;
  margin-top: 100px;
  .form {
    margin: 20px auto;
    li {
      &:last-child {
        margin-bottom: 20px;
      }
    }
  }
  .btn-google {
    padding: 1px 10px 1px 1px;
    display: inline-flex;
    align-items: center;
    img {
      display: block;
      margin-right: 5px;
    }
  }
}

.error {
  background: #c8553d;
  color: #fff;
  border: 1px solid #f9c74f;
  padding: 3px 10px;
  margin: 5px 0 0;
}

/* --------------- Footer --------------- */

.footer {
  margin: 50px 0;
}

/* --------------- Banner --------------- */

.banner {
  background: yellowgreen;
  margin: 0 -15px;
  color: brown;
  h2 {
    font-size: 15px;
    margin: 0;
    padding: 10px;
  }
}

/* --------------- Forms --------------- */

.form {
  max-width: 500px;
  margin-top: 40px;
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    li {
      margin: 0 0 40px;
    }
  }
	label {
		display: block;
		margin: 0 0 10px;
	}
	.radios {
		label {
			display: inline;
			margin: 0 10px 0 5px;
		}
	}
  .text {
		padding: 10px;
  }
  .react-date-picker__wrapper {
		padding: 5px;
	}
	.text, 
  .select, 
  .datepicker{
		width: 100%;
  }
  .text, 
  .select, 
  .react-date-picker__wrapper {
    border: 1px solid #343a40;
    background-color: #2d292d;
    color: #f5f2f5;
    .light-mode & {
      background-color: #e0e0e0;
      border-color: #c9c9c9;
      color: #1f1a1f;
    }
  }
  .react-date-picker__inputGroup__input {
    color: #f5f2f5;
    .light-mode & {
      color: #1f1a1f;
    }
    &:focus {
      outline: none;
    }
  }
  .react-date-picker__calendar-button {
    .react-date-picker__button__icon {
      stroke: #f5f2f5;
      .light-mode & {
        stroke: #1f1a1f;
      }
    }
    &:enabled:hover, 
    &:enabled:focus {
      .react-date-picker__button__icon {
        stroke: #f9c74f;
        .light-mode & {
          stroke: #c8553d;
        }
      }
    }
  }
  .select {
    font-size: 16px;
    display: block;
    padding: 10px 30px 10px 10px;
    appearance: none;
    border: 1px solid #343a40;
    background-color: #2d292d;
    background-image: url('data:image/svg+xml;base64,PCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjI0IiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiPgo8dGl0bGU+a2V5Ym9hcmRfYXJyb3dfZG93bjwvdGl0bGU+CjxwYXRoIGZpbGw9ImxpZ2h0Z3JleSIgZD0iTTcuNDA2IDguNTc4bDQuNTk0IDQuNTk0IDQuNTk0LTQuNTk0IDEuNDA2IDEuNDA2LTYgNi02LTZ6Ij48L3BhdGg+Cjwvc3ZnPgo=');
    background-repeat: no-repeat;
    background-position: right 10px top 50%;
    background-size: 20px auto;
    &:focus {
      outline: none;
    }
    &.year-switch {
      width: 150px;
      color: #f9c74f;
      .light-mode & {
        color: #c8553d;
      }
    }
  }
  .add {
    margin: 5px 0 0;
  }
  .react-calendar {
    background-color: #2d292d;
    color: #f5f2f5;
    .light-mode & {
      background-color: #e0e0e0;
      border-color: #c9c9c9;
      color: #1f1a1f;
    }
    button {
      color: #f5f2f5;
      .light-mode & {
        color: #1f1a1f;
      }
    }
  }
  .react-calendar__tile:enabled:hover, 
  .react-calendar__tile:enabled:focus, 
  .react-calendar__navigation button:enabled:hover, 
  .react-calendar__navigation button:enabled:focus, 
  button.react-calendar__tile--now {
    background-color: #c8553d;
    .light-mode & {
      color: #f5f2f5;
    }
  }
  button.react-calendar__tile--active {
    background-color: #f9c74f;
    color: #3d405b;
    &:hover {
      color: #f5f2f5;
    }
    .light-mode & {
      background-color: #3d405b;
      color: #f5f2f5;
    }
  }
}

/* --------------- Generic Tags & Classes --------------- */

a, 
.btn-link {
  color: #f9c74f;
  &:hover {
    color: #c8553d;
  }
  &:focus {
    outline: none;
  }
  .light-mode & {
    color: #c8553d;
    &:hover {
      color: #3d405b;
    }
  }
}

.btn {
  cursor: pointer;
	display: inline-block;
	background: #f9c74f;
	color: #3d405b;
	border: none;
	padding: 8px 20px;
  text-decoration: none;
  .light-mode & {
    background: #c8553d;
    color: white;
    &:hover {
      background: #3d405b;
      color: white;
    }
  }
  &:hover {
    background: #c8553d;
    color: white;
  }
  &-warning {
    background: #c8553d;
    color: #fff;
    &:hover {
      background: #c8553d;
    }
    .light-mode & {
      background: #3d405b;
    }
  }
  &-sm {
    font-size: 12px;
    padding: 2px 5px;
  }
  &-link {
    background: transparent;
    border: none;
    padding: 0;
    &:hover {
      text-decoration: underline;
    }
  }
  &-mode {
    background: transparent;
    color: #fff;
    padding: 8px 8px 8px 0;
    .light-mode & {
      background: transparent;
      color: #3d405b;
      &:hover {
        background: transparent;
        color: #c8553d;
      }
    }
    &:hover {
      background: transparent;
      color: #c8553d;
    }
    &:focus,
    &:active {
      outline: none;
    }
  }
}

.modal {
  position: fixed; 
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.7);
}

.modal-content {
  background-color: #1F1A1F;
  margin: 5% auto; 
  padding: 20px;
  width: 80%; 
  overflow: auto;
  .light-mode & {
    background-color: #f5f2f5;
  }
}

.modal .accept {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.modal .accept .label {
  flex-grow: 1;
}

.mod-accept .error {
  margin-bottom: 20px;
}

.modal .check {
  margin: 0 10px 0 0;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #f9c74f;
  text-decoration: none;
  cursor: pointer;
}

.warning {
  background: #c8553d;
  padding: 5px;
  margin: 15px 0;
  .light-mode & {
    background: #cedb64;
  }
  &.pad {
    padding: 10px;
  }
}

.listings .button {
  background: transparent;
  border: none;
  color: #f9c74f;
  text-align: left;
  cursor: pointer;
  .light-mode & {
    color: #c8553d;
    &:hover {
      color: #3d405b;
    }
  }
  &:focus,
  &:active {
    outline: none;
  }
}

.listings .button:hover {
  color: #c8553d;
}

.svg {
    width: 1em; 
    height: 1em;
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
}

/* --------------- Loading Graphic --------------- */

.lds-ripple {
  display: block;
  position: relative;
  width: 40px;
  height: 40px;
  margin: 50px auto 0;
  .listings & {
    margin: 5px auto;
  }
}

.lds-ripple div {
  position: absolute;
  border: 2px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  .light-mode & {
    border-color: #c8553d;
  }
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 18px;
    left: 18px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 36px;
    height: 36px;
    opacity: 0;
  }
}
